
import { httpClient } from "@/api/corlytics"

export class CorlyticsService {
    private readonly project_id: number | null = null
    private basePath: string = '/api/v1/'
    private client = httpClient

    constructor() {
    }

    public async getMetadata(project_id: number) {
        const { data } = await this.client.get(this.basePath + 'projects/' + project_id + '/document-metadata/')
        return data
    }
    
    public async getSummary(document_id: string) {
        const { data } = await this.client.get(this.basePath + 'pageitems/' + document_id + '/summary/')
        return data
    }
    
    public async deleteDocument(project_id: number, document_id: number) {
        const { data } = await this.client.delete(`${this.basePath}projects/${project_id}/documents/${document_id}`)
        return data
    }
    
    public async getPublicationContent(project_id: number, document_id: number) {
        const { data } = await this.client.get(`${this.basePath}projects/${project_id}/documents/${document_id}`)
        return data
    }
}