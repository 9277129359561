import axios from 'axios'
import { msalPluginInstance } from '@/plugins/msal-plugin'

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_CORLYTICS_URL,
    withCredentials: false
})

httpClient.interceptors.request.use(
    async (request: any) => {
        request.headers['Pragma'] = 'no-cache'

        try {
            const token = await msalPluginInstance.acquireToken()

            if (token) {
                request.headers['Authorization'] = 'Bearer ' + token.idToken.rawIdToken
            }
            return request
        }
        catch (error) {
        }
    }
)

httpClient.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (!error.response) {        
        if (error.response.status === 401) msalPluginInstance.signOut()

        return Promise.reject(error)
      } 
      else if (error.response.status === 401) {
        msalPluginInstance.signOut()
      } else if ((error.response.status >= 400 && error.response.status <= 410) || error.response.status === 500) {
        return Promise.reject(error.response.data)
      } else if (error.response.status === 419) {
        return Promise.reject(error.response)
      }
      return Promise.reject(error.response.data)
    },
  )
  

export { httpClient }