import { FlexibleDocumentResource } from '@/models/flexibleDocument/FlexibleDocumentResource'

export class FlexibleDocumentUpdateExportSettingsRequest {
  public blacklist: string[]

  constructor(document?: FlexibleDocumentResource) {
    this.blacklist = document ? document.items.filter((item) => item.blacklisted).flatMap((item) => item.uuid) : []
  }

  public toggleBlacklistItem(uuid: string) {
    
    if (this.blacklist.includes(uuid)) {
      this.blacklist = this.blacklist.filter((item) => item !== uuid)
    } else {
      this.blacklist.push(uuid)
    }
  }
}
